import { createApi } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./../../constants";
import { axiosBaseQuery } from "./baseQuery";
import { BetaFormDataType } from "../../pages/BetaRequest/types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    login: builder.mutation<
      { access_token: string },
      { email: string; password: string }
    >({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        data: credentials,
      }),
    }),
    registration: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: "/register",
        method: "POST",
        data,
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/logout",
        method: "POST",
      }),
    }),
    betaRequest: builder.mutation<void, BetaFormDataType>({
      query: (data) => ({
        url: "/beta-request",
        method: "POST",
        data,
        headers: {
          "suppress-toast": true,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegistrationMutation,
  useBetaRequestMutation,
} = authApi;
